<template>
  <div class="container">
    <div class="top">
      <img src="@/assets/img/logo.png" width="72" />
      <div class="title">{{ $t('login').title }}</div>
    </div>
    <!-- 输入框 -->
    <div class="content">
      <div class="form">
        <!-- 手机号-->
        <div class="item">
          <div class="lable">{{ $t('login').Account }}</div>
          <van-field v-model="Account" type="tel" :placeholder="$t('login').placeholder">
          </van-field>
        </div>
        <div class="item">
          <div class="lable">{{ $t('login').Password }}</div>
          <van-field v-model="Password" :type="psdType" :placeholder="$t('login').placeholder1"
            :right-icon="psdType === 'password' ? 'eye' : 'closed-eye'"
            @click-right-icon="psdType = psdType === 'password' ? 'text' : 'password'">
          </van-field>
        </div>
        <!-- 忘记密码 -->
        <div class="tip" @click="$router.push({ name: 'ForgotPassword' })">{{ $t('login').ForgotPassword }}</div>
        <!-- 注册按钮 -->
        <van-button color="#C92B2E" block @click="handleSub" style="border-radius: 5px;height: 40px;">{{
          $t('login').btn
        }}</van-button>
        <!-- 注册 -->
        <div class="tip" @click="$router.push({ name: 'Reg' })">{{ $t('login').Registration }}</div>
        <!-- 联系客服 -->
        <div class="bottoms" @click="goServe">{{ $t('login').server }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import systemApi from '@/api/system'
import { mapGetters } from 'vuex'
import { Token } from '@/utils/mm'
import { EventBus } from '@/utils/event-bus'

export default {
  components: {
  },
  data() {
    return {
      Account: '', //账号
      Password: '',//密码
      psdType: "password", //输入框类型
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
  },
  methods: {
    // 客服
    async goServe() {
      const res = await systemApi.getClientConfig()
      location.href = res.Data.Service
    },
    async handleSub() {
      if (!this.checkForm()) return
      const form = {
        Password: this.Password,
        Account: this.Account
      }
      let res = await userApi.getAccountRefresh(form)
      this.loginSuccess(res.Data.Token)
    },
    async getAccountBase() {
      const aBase = await userApi.getAccountBase()
      this.$store.commit('SET_BASEMSG', aBase.Data)
      this.$router.push({ name: 'Home' })
    },
    async loginSuccess(token) {
      this.$toast.success(this.$t('login').success)
      let res = await userApi.getAccountToken(token)
      Token.set(res.Data.Token)
      EventBus.$emit('Login')
      setTimeout(() => {
        this.getAccountBase()
      }, 1000)
    },
    checkForm() {
      if (!this.Account) {
        this.$toast({
          message: this.$t('login').placeholder,
          position: 'center'
        })
        return false
      }
      if (!this.Password) {
        this.$toast({
          message: this.$t('login').placeholder1,
          position: 'center'
        })
        return false
      }
      return true
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 90px 15px 0 15px;
  background: #ffffff;

  .top {
    text-align: center;

    .title {
      margin: 10px 0 50px 0;
      font-weight: 800;
      font-size: 20px;
      color: #333333;
    }
  }

  .content {
    padding: 0 10px;

    .form {
      background: #ffffff;
      border-radius: 4px;
      padding: 10px;

      .lable {
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        margin: 20px 0 10px 0;
      }

      .tip {
        text-align: center;
        font-size: 14px;
        color: #666666;
      }

      .van-button {
        height: 37px;
        margin: 50px 0 15px 0;
      }

      .bottoms {
        margin: 150px 0 40px 0;
        text-align: center;
        font-size: 14px;
        color: #666666;
      }
    }
  }
}

::v-deep .van-field__value {
  overflow: visible;
  display: grid;
}

::v-deep .van-button__content {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}

.van-cell {
  background: #F2F2F2;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 15px;
}

::v-deep .van-field__right-icon {
  margin: -10px 8px;
  padding: 0px;
  line-height: normal;
}
</style>
